(function () {
  "use strict";

  class HomeCtrl {
    constructor(User, $state, $mdDialog, $mdMedia, Contact, $mdToast) {
      let vm = this;
      vm.ctrlName = "HomeCtrl";
      vm.User = User;
      vm.$state = $state;
      vm.Contact = Contact;
      vm.$mdDialog = $mdDialog;
      vm.$mdToast = $mdToast;
      vm.$mdMedia = $mdMedia;
      vm.contactFormSent = false;
      // vm.contact = {};
      vm.isAtLeastOneCheckboxSelected = function () {
        return (
          vm.contact.highschool_interest || vm.contact.middleschool_interest
        );
      };
    }

    contactSubmit(form) {
      let vm = this;
      if (form.$valid && vm.isAtLeastOneCheckboxSelected()) {
        vm.Contact.contactCreate(vm.contact).then(() => {
          vm.contactFormSent = true;
        });
      }
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast
          .simple()
          .textContent(msg)
          .position("bottom right")
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  angular.module("home").controller("HomeCtrl", HomeCtrl);
})();
